<script>
import { routeLocationKey, useRoute } from "vue-router";
import { nextTick } from 'vue';
import DataTable from 'primevue/datatable';

  const eventTypes = ref([
    // Here were a sample list of event types
  ]
  );
  const relevantCountries = [
            {code: 'CZ', country: 'Česká republika'},
            {code: 'SK', country: 'Slovensko'}
        ];
  export default {
    name: 'eventsManagement',

    data() {
      // console.log("selectEventTypes:", eventTypes);
      return {
        pageTitle: 'Správa směn',
        selectEventTypes: eventTypes,
        selectCountryOptions: relevantCountries,
        event: {
          name: '',
          date: '',
          time: '',
          salary: '',
          type:  '',
          address: {country: '', countryCode: '', city: '', street: '', postal_code: '', house_number: ''},
        },
        gps: '',
        error: null
      };
    }
  }

</script>

<template>
<header class="bg-white shadow">
  <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900">Správa směn</h1>
  </div>
</header>
<main>
  <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
    <!-- Edit event style -->
    <div class="p-2 md:p-4">
      <div class="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
        <h2 class="pl-6 text-2xl font-bold sm:text-xl">Detail směny</h2>

        <div class="grid max-w-2xl mx-auto mt-8">
          <div class="items-center mt-8 sm:mt-14 text-[#202142]">

            <div
                class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
              <div class="w-full">
                <label for="name"
                       class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Název akce</label>
                <input type="text" id="name"
                       class="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                       placeholder="Vaše jméno" v-model="event.name" required>
              </div>
              <div class="mb-2 sm:mb-6">
                <Dropdown v-model="selectedEvent" :options="selectEventTypes" filter optionLabel="name" placeholder="Typ akce" class="w-full md:w-14rem">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <input name="type" type="hidden" :value="`${slotProps.option.code.toLowerCase()}`"/>
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <!-- FROM REGISTER -->
            <h2>Adresa akce</h2>
            <div class="mb-2 sm:mb-6">
              <label for="country" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Národnost*</label>
              <div class="mt-2">
                <v-select v-model="event.address.countryCode" :options="selectCountryOptions"
                          :reduce="country => country.code" label="country" />
                <input type="hidden" name="country" v-model="event.address.country" required>
              </div>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="address" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Kód země*</label>
              <div class="mt-2">
                <input id="country_code" name="country_code" type="text" autocomplete="country_code"
                       placeholder="Kód země"
                       v-model="event.address.countryCode"
                       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6" required disabled/>
              </div>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="city" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Město*</label>
              <input id="city" name="city" type="text" autocomplete="city"
                     placeholder="Město"
                     v-model="event.address.city"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6" required/>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="postal_code" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                PSČ*
              </label>
              <input id="postal_code" name="postal_code" type="text" autocomplete="postal_code"
                     placeholder="PSČ"
                     v-model="event.address.postal_code"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     pattern="[0-9]{3} [0-9]{2}"
                     required/>
              <small>Zadejte s mezerou</small>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="street" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Ulice*</label>
              <input id="street" name="street" type="text" autocomplete="street"
                     placeholder="Ulice"
                     v-model="event.address.street"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6" required/>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="house_number" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                Číslo popisné*
              </label>
              <input id="house_number" name="house_number" type="text" autocomplete="house_number"
                     placeholder="Číslo popisné"
                     v-model="event.address.house_number"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     pattern="[0-9]+"
                     required/>
            </div>

            <h2>Termín</h2>
            <div class="mb-2 sm:mb-6">
              <label for="date" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                Datum akce
              </label>
              <input id="date" name="date" type="date" autocomplete="date"
                     placeholder="Datum akce"
                     v-model="event.date"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                   focus:ring-indigo-600 sm:text-sm sm:leading-6" required/>
            </div>
            <div class="mb-2 sm:mb-6">
              <label for="salary" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                Odměna (základ)
              </label>
              <div class="flex">
                <input id="salary" name="salary" type="number"
                       autocomplete="salary"
                       placeholder="Odměna (základ)"
                       v-model="event.salary"
                       class="flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                               ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                               focus:ring-indigo-600 sm:text-sm sm:leading-6"
                       required/>
              </div>
            </div>
            <div class="mb-2 sm:mb-6">
              <label for="time" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                Čas
              </label>
              <div class="flex">
                <input id="time" name="time" type="text"
                      autocomplete="time"
                      v-model="event.time"
                      class="flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                              focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      required/>
              </div>
            </div>

            <div v-if="error" class="error-message" style="color: red">
              {{ error }}
            </div>

            <div class="flex justify-end">
              <button type="submit"
                      class="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4
                      focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full
                      sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700
                      dark:focus:ring-indigo-800">
                Uložit
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="p-2 md:p-4">
      <h2 class="pl-6 text-2xl font-bold sm:text-xl">Akce</h2>

      <!-- Data table section -->
      <!-- Use, e.g. @click="editProduct(slotProps.data)" -->

      <!-- NOTE: There can be also country code and other stuff, check the official docummentation. -->
      <div class="card">
          <DataTable v-model:filters="filters" :value="events" paginator :rows="10" dataKey="id" filterDisplay="menu" :loading="loading"
                  :globalFilterFields="['name', 'type', 'address', 'date', 'time']">
              <template #header>
                  <div class="flex justify-content-end">
                    <IconField iconPosition="left">
                        <InputText v-model="filters['global'].value" placeholder="Hledat dle klíčového slova" />
                    </IconField>
                  </div>
              </template>
              <template #empty> Nebyly nalezeny žádné směny. </template>
              <template #loading> Načítám data o akcích. Prosíme o strpení. </template>

              <Column field="name" header="Název akce" style="min-width: 12rem" sortable>
                  <template #body="{ data }">
                      {{ data.name }}
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Hledat dle jména" />
                  </template>
              </Column>

              <Column field="type" header="Typ akce" style="min-width: 12rem" sortable>
              <template #body="{ data }">
                  {{ data.type }} <!-- Treat type as a string -->
              </template>
              <template #filter="{ filterModel, filterCallback }">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Hledat dle typu akce" />
              </template>
            </Column>


              <Column field="address" header="Adresa" filterField="address" style="min-width: 12rem">
                  <template #body="{ data }">
                      <div class="flex align-items-center gap-2">
                          <span>{{ data.address.street }}, {{ data.address.house_number }}, {{ data.address.city }}, {{ data.address.postal_code }}</span>
                      </div>
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country" />
                  </template>
              </Column>


              <Column field="status" header="Status" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
                  <template #body="{ data }">
                      <Tag :value="data.status" :severity="getSeverity(data.status)" />
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                          <template #option="slotProps">
                              <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                          </template>
                      </Dropdown>
                  </template>
              </Column>



              <Column field="date" header="Datum" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    {{ formatDate(data.date) }} 
                </template>
              </Column>

              <Column field="time" header="Čas" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    {{data.time }}
                </template>
              </Column>

              <Column field="verified" header="Ověření" dataType="boolean" style="min-width: 6rem">
                  <template #body="{ data }">
                      <i class="pi" :class="{ 'pi-check-circle text-green-500': data.verified, 'pi-times-circle text-red-400': !data.verified }"></i>
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
                  </template>
              </Column>
              <Column field="edits" header="Úpravy" style="min-width:8rem">
                  <template #body="slotProps">
                      <Button icon="pi pi-pencil" outlined rounded class="mr-2"  />
                      <Button icon="pi pi-trash" outlined rounded severity="danger" />
                  </template>
              </Column>
          </DataTable>
      </div>
    </div>
    <!-- END Data table section -->
  </div>
</main>

</template>

<script setup>

import {onMounted, onUnmounted, watch, ref} from "vue";
import { FilterMatchMode } from 'primevue/api';
import { EventService } from '@/services/EventService';
import Dropdown from 'primevue/dropdown';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Button from 'primevue/button';

  const selectedEvent = ref();

    const events = ref();
    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'address': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        attachements: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
        type: { value: null, matchMode: FilterMatchMode.STARTS_WITH }, // Add this line
    });
    
    const statuses = ref(['zrušená', 'proběhlá', 'nová']);
    const loading = ref(true);

    onMounted(() => {
      EventService.getEventsMedium().then((data) => {
        events.value = EventService.getData(); // Adjust this if you need to call a different method
        loading.value = false;
      });
    });

    const getEvents = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);

            return d;
        });
    };    
    const formatDate = (value) => {
        if (!value) {
            // Handle the case where the value is null, undefined or an empty string
            return '';
        }
        const date = new Date(value);
        if (isNaN(date)) {
            // Handle the case where the value is not a valid date string
            return 'Invalid Date';
        }
        return date.toLocaleDateString('cs', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };
    const getSeverity = (status) => {
      switch (status) {
        case 'zrušená':
            return 'danger';

        case 'proběhlá':
            return 'success';

        case 'nová':
            return 'info';
      }
    }

</script>